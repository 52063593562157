<template>
  <div style="width: 100%;height: 100vh;position: relative;overflow-y: auto">
    <div style="height: 284px;position: relative">
      <img src="../../../assets/share_top.png" style="width: 100%;height: 56px;object-fit: fill">
      <div class="share-collection-top">
        <div class="merchant-container flex-row-center">
          <img v-if="merchantInfo.avatar_address" :src="'http://'+merchantInfo.avatar_address"
               style="border-radius: 50%;width: 32px;height: 32px;object-fit: cover;margin-right: 0.67rem">
          <p class="merchant-name">{{ merchantInfo.merchant_name }}配套音频小课</p>
        </div>
        <div style="margin-top: 25px;display: flex;flex-direction: row;align-items: flex-start;width: 100%;">
          <img v-if="albumInfo.cover_pic_url" :src="albumInfo.cover_pic_url"
               style="width: 96px;height: 96px;object-fit: cover">
          <img v-else src="../../../assets/defalut_album_v1.png"
               style="width: 96px;height: 96px;object-fit: cover">
          <div style="margin-left: 12px;">
            <p class="collection-name">
              {{ albumInfo.album_name }}</p>
            <p class="collection-intro">
              {{ albumInfo.album_intro }}</p>
            <div class="flex-row" style="flex-wrap: wrap;">
              <p class="counts-container" style="margin-right: 10px;margin-top: 6px;">共{{ songCounts }}个内容</p>
              <p class="counts-container" style="margin-right: 10px;margin-top: 6px;">预估{{
                  Math.ceil(songCounts / 5)
                }}个课时</p>
              <p class="counts-container" style="margin-top: 6px;">预计学习{{ Math.ceil(songCounts / 5 / 2) }}周</p>
              <!--              <p class="counts-container">共{{ allContentCount }}个内容</p>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="collection-desc">
      <div class="flex-row" style="align-items: center">
        <img src="../../../assets/share_collection_logo.png">
        <p style="margin-left: 13px;color: #333333;font-size: 16px;font-weight: bold;font-family: PingFang SC-Bold, PingFang SC; ">
          专辑介绍</p>
      </div>
      <pre class="introPre"
           style="margin-top: 14px;color: #454651;font-size: 14px;font-weight: 400;font-family: PingFang SC-Regular, PingFang SC;line-height: 28px">
        {{ albumInfo.simple_intro }}</pre>
      <p class="btn-purchase" @click="gotoPurchase" v-if="purchase_status ==0"><span
          style="text-decoration: line-through;">(原价￥{{ albumInfo.album_price }})</span>￥{{ price }}
        立即购买</p>
      <div style="width: 100%;height: 100px">
      </div>
    </div>
  </div>
</template>

<script>
import {getAlbumInfo, getMerchantInfo} from '../../../api/keneng'
import '../../../assets/common/common.css'
import {getOpenId, setContentId, setMerchantId, setPurchaseType} from "../../../utils/store";

export default {
  name: "PurchaseAlbumLink",
  data() {
    return {
      purchase_status: 0,
      merchant_id: this.$route.query.merchant_id,
      album_id: this.$route.query.album_id,
      merchantInfo: '',
      albumInfo: '',
      songCounts: 0,
      price: ''
    }
  },
  mounted() {
    if (this.$route.query.purchase_status) {
      this.purchase_status = this.$route.query.purchase_status
    }
    this.fetchData()
  },
  methods: {
    gotoPurchase() {

      setMerchantId(this.merchant_id)
      setContentId(this.album_id)
      setPurchaseType('album')

      const open_id = getOpenId()
      if (open_id && open_id != '') {
        this.$router.replace({
          path: '/share-pay',
          query: {
            open_id: open_id
          }
        })
        return
      }

      const next_route = '/share-pay'
      const appId = 'wxda915e8dce4fe609'
      const redirectUrl = 'https://login.kenengxueyuan.com/link?next_route=' + next_route
      window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appId + '&redirect_uri=' + redirectUrl + '&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect&connect_redirect=1'
    },
    fetchData() {
      getMerchantInfo(this.merchant_id).then((res) => {
        this.merchantInfo = res.data.data
      })
      getAlbumInfo(this.album_id, this.merchant_id).then((res) => {
        this.albumInfo = res.data.data
        document.title = '购买' + this.albumInfo.album_name
        this.price = this.albumInfo.mechant_content.price
        this.songCounts = this.albumInfo.album_songs.length
      })
    }
  }
}
</script>

<style scoped lang="less">

.btn-purchase {
  cursor: pointer;
  margin-top: 60px;
  text-align: center;
  width: 100%;
  height: 40px;
  line-height: 40px;
  background: #FF5E45;
  border-radius: 24px 24px 24px 24px;
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #FFFFFF;
}


::-webkit-scrollbar {
  display: none;
}

.collection-desc {
  padding: 23px 28px;
  box-sizing: border-box;
  top: 260px;
  position: absolute;
  background-color: white;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  width: 100%;
  min-height: 300px;
}


.share-collection-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 12px 20px;
  box-sizing: border-box;
  margin-top: -5px;
  position: relative;
  height: 228px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-image: url("../../../assets/share_top_bg.png");
  background-size: 100% 100%;
}

.merchant-container {
  background: #FCC52D;
  box-shadow: 0px 4px 20px 1px rgba(252, 197, 45, 0.16);
  border-radius: 20px 20px 20px 20px;
  padding: 4px 12px;
  box-sizing: border-box;
}

.merchant-name {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 18px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #FFFFFF;
}

.collection-name {
  color: white;
  font-size: 18px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500
}

.collection-intro {
  margin-top: 8px;
  line-height: 18px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  font-size: 12px;
  color: white;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: 400
}

.counts-container {
  padding: 3px 10px;
  box-sizing: border-box;
  font-size: 10px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  border-radius: 6px;
  border: 1px solid #FFFFFF;
}

.introPre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

</style>
